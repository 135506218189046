@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes scrollIndicator {
  0% { transform: translate(-50%, 0); opacity: 1; }
  100% { transform: translate(-50%, 20px); opacity: 0; }
}

.nav-blur {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.hero-title {
  background: linear-gradient(300deg, #00f6ff, #786fee, #ff77ff);
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 8s linear infinite;
}

.hero-bg {
  background: radial-gradient(circle at 50% 50%, 
    rgba(76, 29, 149, 0.1) 0%,
    rgba(30, 58, 138, 0.1) 25%,
    rgba(0, 0, 0, 0) 50%);
  animation: gradient 15s ease infinite;
  background-size: 200% 200%;
}

.service-card {
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s ease;
  position: relative;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.2);
}

.service-card::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(45deg, #0a080f, #4a1096, #1906c5);
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.service-card:hover::before {
  opacity: 1;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.image-container:hover::after {
  left: 100%;
}

.button-primary {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  position: relative;
}

.button-primary:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.button-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9999px;
  padding: 2px;
  background: linear-gradient(45deg, #00f6ff, #786fee, #ff77ff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.stats-container {
  background: linear-gradient(
    45deg,
    rgba(76, 29, 149, 0.1),
    rgba(30, 58, 138, 0.1)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-gradient {
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(17, 24, 39, 0.8)
  );
}

.mobile-menu {
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.mobile-menu.open {
  transform: translateX(0);
}

.mobile-menu-item {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.mobile-menu.open .mobile-menu-item {
  opacity: 1;
  transform: translateY(0);
}

.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}

.scroll-indicator::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 50%;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  transform: translateX(-50%);
  animation: scrollIndicator 2s infinite;
}
